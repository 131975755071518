import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const App = ({data, pageContext}) => {
  let title = pageContext?.category
  let links = data.allMarkdownRemark.nodes.map((object, i) => {
    let path = object.frontmatter.configurator_id
    if(path){
      path = '/qc1/'+path
      return (<div key={i}><Link to={path}>{object.frontmatter.product_name}</Link></div>)
    }else{
      return (null)
    }
  })
  return(
    <Layout fsOnly={true}>
    <h1>{title}</h1>
    {links}
    </Layout>
  )
}

export default App

export const query = graphql`
query($category: String!){
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/data/products/"}, frontmatter: {product_category: {eq: $category}}}, sort: {order: ASC, fields: [frontmatter___product_name]}) {
    nodes {
      frontmatter {
        product_name
        configurator_id
        smart_code
        product_category
        sort_name
        pricing_options {
          description
          model
          weight
          weight_options
          unit_text
          modified_standard
          base_configuration
          net_price {
            currency
            value
          }
        }
        hardware_kits {
          hardware_kit
          description
          default_quantity
          smart_code_restriction {
            position
            smart_code
          }
        }
      }
    }
  }
}
`
